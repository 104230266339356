import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    width: 200,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export default function({ name, title }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Avatar className={classes.avatar} />
      <Typography align="center" gutterBottom className={classes.title}>
        {name || 'John Smith'}
      </Typography>
      <Typography align="center" gutterBottom className={classes.title}>
        {title || 'Developer'}
      </Typography>
    </div>
  )
}
