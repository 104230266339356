import React from 'react'
import Container from '@material-ui/core/Container'
import GridList from '@material-ui/core/GridList'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Member from '../components/member'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    fontWeight: 'bold',
  },
  teams: {
    justifyContent: 'center',
  },
}))

const NotFoundPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="Team" />
      <Container component="main" maxWidth="md" className={classes.container}>
        <Typography
          variant="h4"
          align="left"
          gutterBottom
          className={classes.title}
        >
          Our Team
        </Typography>
        <GridList className={classes.teams}>
          <Member name="Hardy" title="Engineering" />
          <Member name="Brady" title="Quality Assurance" />
          <Member name="R. Y." title="Design" />
        </GridList>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
